import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { REACT_TO_POST } from '../../../constants/interactions';
import { ReactionsDisplayState } from '../../../containers/post-reactions/post-reaction-types';
import { ReactionsConfig } from '../../../containers/post-reactions/post-reactions-config.utils';
import withAuth from '../../../hoc/with-auth';
import withPermissions from '../../../hoc/with-permissions';
import { RootState } from '../../../types/store-types';
import { VerticalSeparator } from '../../separator';
import { CommentButton } from '../action-buttons/comment-button';
import { ReactionsButton } from '../action-buttons/reactions-button';
import styles from './post-multi-row-footer.scss';

export type FooterActionsProps = {
  post: any;
  reactionsConfig: ReactionsConfig;
  postLink: string;
  showReactions: boolean;
  isPostCardSmall: boolean;
  reactions: ReactionsDisplayState;
  isMobile: boolean;
  t: any;
  showCommentButton: boolean;
  shouldFocusCommentBox?: boolean;
} & FooterActionsRuntimeProps;

type FooterActionsRuntimeProps = {
  forPublicUser: any;
  onReactionClick: (reactionCode: ReactionCode) => void;
};

const FooterActionsComponent: React.FC<FooterActionsProps> = ({
  reactions,
  reactionsConfig,
  forPublicUser,
  onReactionClick,
  isMobile,
  post,
  postLink,
  t,
  showReactions,
  isPostCardSmall,
  showCommentButton,
  shouldFocusCommentBox,
}) => {
  const desktopRootClass = isPostCardSmall
    ? styles.actionsContainerSmallSize
    : styles.actionsContainerMediumSize;

  const mobileRootClass = showReactions
    ? styles.actionContainerMobile
    : styles.actionContainerMobileSingle;

  return (
    <div className={isMobile ? mobileRootClass : desktopRootClass}>
      {showReactions && (
        <div className={styles.actionItem}>
          <ReactionsButton
            reactions={reactions}
            reactionsConfig={reactionsConfig}
            forPublicUser={forPublicUser}
            onReactionClick={onReactionClick}
            postId={post._id}
            t={t}
            isMobile={isMobile}
          />
        </div>
      )}
      {showReactions && isMobile ? (
        <VerticalSeparator className={styles.verticalSeparator} />
      ) : null}
      {showCommentButton && (
        <div className={styles.actionItem}>
          <CommentButton
            post={post}
            postLink={postLink}
            t={t}
            type="action"
            isLink={true}
            shouldFocusCommentBox={shouldFocusCommentBox}
          />
        </div>
      )}
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any, host: any) => ({
  onReactionClick: (reactionCode: ReactionCode) => {
    actions.interactionStarted(REACT_TO_POST);
    return actions.reactToPost({
      postId: ownProps.post._id,
      reactionCode,
    });
  },
});

export const FooterActions = flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withAuth,
)(FooterActionsComponent);
