import { REACT_TO_POST } from '../../constants/interactions';
import {
  isCacheableRendering,
  isEditor,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import { RootState } from '../../types/store-types';
import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';
import { getReactionsDisplayState } from '../../containers/post-reactions/post-reactions-selectors';
import {
  getCardSize,
  getIsSocialSharingLinksEnabled,
  getIsViewsCountEnabled,
} from '../../selectors/app-settings-selectors';
import { ReactionsDisplayState } from '../../containers/post-reactions/post-reaction-types';
import { OpenReactionsModalProps } from '../../actions/open-reactions-modal-actions';

export type PostFooterRuntimeProps = {
  isViewsCountEnabled: boolean;
  reactions: ReactionsDisplayState;
  onReactionClick: (reactionCode: ReactionCode) => void;
  openReactionsModal: (props: OpenReactionsModalProps) => void;
  isShareButtonEnabled: boolean;
  cardSize: any;
};

export const mapPostFooterRuntimeToProps = (
  state: RootState,
  ownProps: any,
  actions: any,
  host: any,
): PostFooterRuntimeProps => ({
  isViewsCountEnabled: !isCacheableRendering(state) && getIsViewsCountEnabled(state, host.style),
  reactions: getReactionsDisplayState(state, ownProps.post._id),
  onReactionClick: (reactionCode: ReactionCode) => {
    actions.interactionStarted(REACT_TO_POST);
    return actions.reactToPost({
      postId: ownProps.post._id,
      reactionCode,
    });
  },
  openReactionsModal: actions.openReactionsModal,
  isShareButtonEnabled:
    !isEditor(state) && !isPreview(state) && getIsSocialSharingLinksEnabled(state, host.style),
  cardSize: getCardSize(state, host.style),
});
