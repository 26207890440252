import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { HorizontalSeparator } from '../../separator';
import { CARD_SIZE_SMALL } from '@wix/communities-forum-client-commons';
import withTranslate from '../../../../common/components/with-translate/with-translate';
import { useReactionsConfig } from '../../../hooks/use-reactions-config';
import { PostMetadata } from '../metadata/post-metadata';
import withDeviceType from '../../../hoc/with-device-type';
import withPermissions from '../../../hoc/with-permissions';
import withAuth from '../../../hoc/with-auth';
import { FooterActions } from './post-multi-row-footer-actions';
import { getPostFooterVariantByOrigin } from '../post-footer-utils';
import PostListItemRecentActivity from '../../post-list-item-recent-activity';
import PostWithSidebarSocialActions from '../../post-social-actions';
import { mapPostFooterRuntimeToProps, PostFooterRuntimeProps } from '../post-footer-props-mapper';
import classNames from 'classnames';

import { classes } from '../post-reactions.st.css';
import styles from './post-multi-row-footer.scss';

export type PostMultiRowFooterOrigin = 'postListCard' | 'postListItem' | 'postPageMobile';

type PostMultiRowFooterProps = {
  post: any;
  category: any;
  origin: PostMultiRowFooterOrigin;
  shouldFocusCommentBox?: boolean;
};

type PostCardFooterHOCProps = {
  t: any;
  isMobile: boolean;
  can: any;
};

const PostMultiRowFooterComponent: React.FC<
  PostMultiRowFooterProps & PostCardFooterHOCProps & PostFooterRuntimeProps
> = (props) => {
  const {
    origin,
    post,
    category,
    isMobile,
    cardSize,
    isShareButtonEnabled,
    can,
    reactions,
    t,
    openReactionsModal,
    isViewsCountEnabled,
    shouldFocusCommentBox,
  } = props;
  const postLink = `/${category?.slug}/${post?.slug}`;
  const reactionsConfig = useReactionsConfig(category);
  const isPostCardSmall = cardSize === CARD_SIZE_SMALL;

  const {
    isReactionsButtonVisible,
    isCommentsCountVisible,
    isPostViewsCountVisible,
    isReactionsCountVisible,
    isShareButtonVisible,
    isCommentButtonVisible,
    isRecentActivityVisible,
  } = getPostFooterVariantByOrigin({
    origin,
    reactionsConfig,
    canShare: post && can('read', 'post', post) && isShareButtonEnabled,
    isViewsCountEnabled,
  });

  return (
    <div className={classNames(styles.footer, classes.rootReactionsVars, 'forum-text-color')}>
      <div className={classNames(styles.metadataContainer, classes.reactionsMetadata)}>
        <PostMetadata
          post={post}
          reactionsConfig={reactionsConfig}
          postLink={postLink}
          reactions={reactions}
          t={t}
          isMobile={isMobile}
          showReactionsCount={isReactionsCountVisible}
          showCommentsCount={isCommentsCountVisible}
          showPostViewsCount={isPostViewsCountVisible}
          openReactionsModal={openReactionsModal}
        />
        {isRecentActivityVisible && (
          <PostListItemRecentActivity post={post} category={category} isAvatarSmall={true} />
        )}
        {isShareButtonVisible && (
          <PostWithSidebarSocialActions
            post={post}
            category={category}
            className={classNames(styles.socialActions, styles.extraSpacing)}
            path={`/${category?.slug}/${post?.slug}`}
          />
        )}
      </div>
      <HorizontalSeparator theme={undefined} />
      <FooterActions
        post={post}
        reactionsConfig={reactionsConfig}
        postLink={postLink}
        reactions={reactions}
        t={t}
        isMobile={isMobile}
        showReactions={isReactionsButtonVisible}
        isPostCardSmall={isPostCardSmall}
        showCommentButton={isCommentButtonVisible}
        shouldFocusCommentBox={shouldFocusCommentBox}
      />
    </div>
  );
};

export const PostMultiRowFooter = flowRight(
  connect(mapPostFooterRuntimeToProps),
  withAuth,
  withPermissions,
  withTranslate,
  withDeviceType,
)(PostMultiRowFooterComponent);
