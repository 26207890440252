import React from 'react';

export const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12.188"
    viewBox="0 0 15 12.188"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M2615.5,878.848a6.088,6.088,0,0,1-1.76.484,3.1,3.1,0,0,0,1.35-1.7,6.189,6.189,0,0,1-1.95.747,3.083,3.083,0,0,0-5.33,2.105,3.248,3.248,0,0,0,.08.7,8.732,8.732,0,0,1-6.34-3.216,3.016,3.016,0,0,0-.42,1.547,2.9,2.9,0,0,0,1.13,2.562,9.461,9.461,0,0,1-1.63-.385v0.039a3.3,3.3,0,0,0,2.71,3.017,2.234,2.234,0,0,1-.69.108,2.41,2.41,0,0,1-.52-0.055,3.107,3.107,0,0,0,2.9,2.137,6.112,6.112,0,0,1-3.81,1.317,5.875,5.875,0,0,1-.72-0.043,8.757,8.757,0,0,0,13.48-7.373c0-.133-0.01-0.266-0.01-0.4A6.214,6.214,0,0,0,2615.5,878.848Z"
      transform="translate(-2600.5 -877.406)"
    />
  </svg>
);
