import React from 'react';
import { CommentButton } from '../action-buttons/comment-button';
import { ReactionsCount, ReactionsCountProps } from './reactions-count';
import { PostViewsCount } from './post-views-count';
import styles from './post-metadata.scss';
import { ReactionsConfig } from '../../../containers/post-reactions/post-reactions-config.utils';
import { OpenReactionsModalProps } from '../../../actions/open-reactions-modal-actions';

type PostMetadataProps = {
  post: any;
  postLink: string;
  reactionsConfig: ReactionsConfig;
  showReactionsCount?: boolean;
  showPostViewsCount?: boolean;
  showCommentsCount?: boolean;
  openReactionsModal?: (props: OpenReactionsModalProps) => void;
  commentsCountShouldOpenPost?: boolean;
} & Omit<ReactionsCountProps, 'postId' | 'openReactionsModal'>;

export const PostMetadata: React.FC<PostMetadataProps> = ({
  post,
  reactionsConfig,
  postLink,
  t,
  reactions,
  isMobile,
  openReactionsModal,
  showReactionsCount = true,
  showPostViewsCount = true,
  showCommentsCount = true,
  commentsCountShouldOpenPost = true,
}) => {
  const reactionsCount =
    showReactionsCount && reactions.total > 0 ? (
      <ReactionsCount
        reactions={reactions}
        reactionsConfig={reactionsConfig}
        openReactionsModal={openReactionsModal ? openReactionsModal : () => {}}
        postId={post._id}
        t={t}
        isMobile={isMobile}
        className={openReactionsModal ? 'forum-link-hover-color' : styles.defaultCursor}
      />
    ) : null;

  const commentsCount = showCommentsCount ? (
    <CommentButton
      post={post}
      postLink={postLink}
      t={t}
      type="metadata"
      isLink={commentsCountShouldOpenPost}
    />
  ) : null;

  const postViewsCount = showPostViewsCount ? (
    <PostViewsCount post={post} showViewCount={showPostViewsCount} t={t} />
  ) : null;

  const metadataComponentsList = [reactionsCount, commentsCount, postViewsCount];

  return (
    <div className={styles.container}>
      {metadataComponentsList
        .filter((c) => c !== null)
        .map((Component) => (
          <div className={styles.listItem}>{Component}</div>
        ))}
    </div>
  );
};
