import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import share from '../../services/share';
import PostShareButton from '../post-share-button';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-social-actions.scss';
import withNativeShare from '../../hoc/with-native-share';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import { LINK_COPIED_SUCCESS, LINK_COPIED_FAILURE } from '../messages/message-types';
import { copyTextToClipboard } from '../../../utils/copy-text-to-clipboard';

export class PostSocialActions extends Component {
  sharePost = () => {
    const { sectionUrl, post, category, openNativeShareIfSupported, showMessage, onShareClick } =
      this.props;

    const postUrl = `${sectionUrl}/${category.slug}/${post.slug}`;

    onShareClick();

    openNativeShareIfSupported(
      {
        title: post.title,
        url: postUrl,
      },
      async () => {
        if (copyTextToClipboard(postUrl)) {
          showMessage(LINK_COPIED_SUCCESS);
        } else {
          showMessage(LINK_COPIED_FAILURE);
        }
      },
    );
  };

  handleShare = (socialNetwork) => {
    share(socialNetwork, this.props.href);
  };

  render() {
    const { className, t } = this.props;

    return (
      <div
        className={classNames(
          className,
          'forum-text-color',
          this.props.titleFontClassName,
          styles.container,
        )}
        tabIndex="-1"
      >
        <span className={styles.button}>
          <PostShareButton
            type="link"
            title={t('share-button.via-link')}
            onClick={this.sharePost}
            text={t('share-button.link')}
            contentFontClassName={this.props.contentFontClassName}
          />
        </span>
      </div>
    );
  }
}

PostSocialActions.propTypes = {
  post: PropTypes.object,
  category: PropTypes.object,
  className: PropTypes.string,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  sectionUrl: PropTypes.string,
  openNativeShareIfSupported: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    showMessage: actions.showMessage,
    sectionUrl: getSectionUrl(state),
    onShareClick: () => {
      actions.buttonClicked({
        action: 'share-post-social',
        name: 'action_click',
        type: 'post',
        categoryId: ownProps.post.categoryId,
        postId: ownProps.post._id,
      });
    },
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withNativeShare,
)(PostSocialActions);
